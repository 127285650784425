import {ajax_address_by_user, ajax_technician_category, change_inputPrice_by_select_typeService, ajax_notification_message_by_role} from "./module/service";

let element = document.getElementById('select_category');
let selectElement = document.getElementById('select_technician_hopassist');


export function get_address_by_user(){
    let element = document.getElementById('select_user');
    if(element == null){
    element = document.getElementById('select_user_consumer');
    }
    let selectElement = document.getElementById('client_address');

    if(element != null){
        ajax_address_by_user(element, selectElement)
    }
}

export function get_technician_by_category(){

    element.addEventListener('change', function(){
        ajax_technician_category(element, selectElement);
    })
}

export function get_technician_by_category_window_load(){

    ajax_technician_category(element, selectElement);
}

export function get_notification_message_by_role(){

    let selectElement = document.getElementById('select_notifications_clients');
    let selectElementTwo = document.getElementById('select_message_type');

    ajax_notification_message_by_role(selectElement, selectElementTwo);
}

export function change_dispay_price(){

    let element = document.getElementById('select_s_type');
    let blockForfait = document.getElementById('group-forfait');
    let blockTime = document.getElementById('group-time');

    change_inputPrice_by_select_typeService(element, blockForfait, blockTime);

    element.addEventListener('change', function(){
        let blockForfait = document.getElementById('group-forfait');
        let blockTime = document.getElementById('group-time');

        change_inputPrice_by_select_typeService(element, blockForfait, blockTime);
    })
}

// export function notification_clients_select(){
//     let elementChoice = document.getElementById('select_notifications_clients');
//     let elementUserSelect = document.getElementById('select_clients');

//     elementChoice.addEventListener('change', function(){
//         if(elementChoice.value == 'select'){
//             elementUserSelect.style.display = 'block';
//         }else{
//             elementUserSelect.style.display = 'none';
//         }
//     })




// }
