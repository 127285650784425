/*
-------------------------------                          -------------------------------
-------------------------------                          -------------------------------
-------------------------------     Function not use     -------------------------------
-------------------------------                          -------------------------------
-------------------------------                          -------------------------------
*/

// function for get technician by user when you create a service
/*
function get_technician_by_user(){
  let element = document.getElementById('select_user');

  if(element != null){
    let selectElement = document.getElementById('select_technician_company');

    element.addEventListener('change', function(){

      let httpRequest = new XMLHttpRequest();
      let currentUrl = window.location.origin + '/';

      let meta = document.getElementById('meta_csrf');
      let metaValue = meta.getAttribute('content');

      var data;

      httpRequest.open('POST', currentUrl + 'get_technician_by_user/')
      httpRequest.setRequestHeader('Authorization', 'Bearer ' +  metaValue);
      httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      //httpRequest.setRequestHeader("Content-type","application/x-www-form-urlencoded");
      httpRequest.setRequestHeader('X-CSRF-TOKEN', metaValue)
      httpRequest.send(element.value);

      httpRequest.addEventListener("loadend", function() {
        if (httpRequest.status === 200) {

          while(selectElement.firstChild) {
            selectElement.removeChild(selectElement.firstChild);
          }

          data = JSON.parse(httpRequest.responseText)

          if(data.length > 0) {
            let firstChild = document.createElement('option');
            selectElement.appendChild(firstChild)
            for(let i = 0; i < data.length; i++) {
              let optionElement = document.createElement('option')
              optionElement.value = data[i].id
              optionElement.textContent = data[i].t_lastname + ' ' + data[i].t_firstname
              selectElement.appendChild(optionElement)
            }
          }else{
            let optionElement = document.createElement('option')
            optionElement.value = "";
            optionElement.textContent = "Aucun technicien rattaché à l'entreprise";
            selectElement.appendChild(optionElement)
          }
        }else{
          console.log('il y\'a eu un problème avec la requete')
        }
      }, false);
    })
  }
}
*/

/*
-------------------------------                          -------------------------------
-------------------------------                          -------------------------------
-------------------------------     Function     use     -------------------------------
-------------------------------                          -------------------------------
-------------------------------                          -------------------------------
*/

// function for get address by user when you create a service
function ajax_address_by_user(element, selectElement){

  element.addEventListener('change', function(){

    console.log('changement')

    let httpRequest = new XMLHttpRequest();
    let currentUrl = window.location.origin + '/';

    let meta = document.getElementById('meta_csrf');
    let metaValue = meta.getAttribute('content');

    var data;

    var params = '?0=' + element.value;

    httpRequest.open('GET', currentUrl + 'get_address_by_user/' + params)
    httpRequest.setRequestHeader('Authorization', 'Bearer ' +  metaValue);
    httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    //httpRequest.setRequestHeader("Content-type","application/x-www-form-urlencoded");
    httpRequest.setRequestHeader('X-CSRF-TOKEN', metaValue)
    httpRequest.send();


    httpRequest.addEventListener("loadend", function() {
      if (httpRequest.status === 200) {

        while(selectElement.firstChild) {
          selectElement.removeChild(selectElement.firstChild);
        }

        data = JSON.parse(httpRequest.responseText)
        if(data.length > 0) {

          let firstChild = document.createElement('option');
          selectElement.appendChild(firstChild)
          for(let i = 0; i < data.length; i++) {
            let optionElement = document.createElement('option')
            optionElement.value = data[i].id
            optionElement.textContent = data[i].ua_name + ' | ' + data[i].ua_no_street + ' ' + data[i].ua_streetname + ', ' + data[i].ua_GETalcode + ' ' + data[i].ua_city
            selectElement.appendChild(optionElement)
          }
        }else{
          let optionElement = document.createElement('option')
          optionElement.value = "";
          optionElement.textContent = 'Aucune adresse existant pour le client';
          selectElement.appendChild(optionElement)
        }
      }else{
        console.log('il y\'a eu un problème avec la requete')
      }
    }, false);
  })
}

// function ajax for get technician by category

function ajax_technician_category(element, selectElement){
  let httpRequest = new XMLHttpRequest();
  let currentUrl = window.location.origin + '/';

  let meta = document.getElementById('meta_csrf');
  let metaValue = meta.getAttribute('content');

  var data;

  httpRequest.open('POST', currentUrl + 'get_technician_by_category/')
  httpRequest.setRequestHeader('Authorization', 'Bearer ' +  metaValue);
  httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  //httpRequest.setRequestHeader("Content-type","application/x-www-form-urlencoded");
  httpRequest.setRequestHeader('X-CSRF-TOKEN', metaValue)
  httpRequest.send(element.value);

  httpRequest.addEventListener("loadend", function() {
    if (httpRequest.status === 200) {

      while(selectElement.firstChild) {
        selectElement.removeChild(selectElement.firstChild);
      }

      data = JSON.parse(httpRequest.responseText)

      if(data.length > 0) {
        let firstChild = document.createElement('option');
        selectElement.appendChild(firstChild)
        for(let i = 0; i < data.length; i++) {
          let optionElement = document.createElement('option')
          optionElement.value = data[i].id
          optionElement.textContent = data[i].t_firstname + ' ' + data[i].t_lastname + ' | ' + data[i].t_hourly_rate + '/' + data[i].t_half_day_rate + '/' +  data[i].t_day_rate + '€'
          selectElement.appendChild(optionElement)
        }
      }else{
        let optionElement = document.createElement('option')
        optionElement.value = "";
        optionElement.textContent = "Aucun technicien rattaché à cette catégorie";
        selectElement.appendChild(optionElement)
      }
    }else{
      console.log('il y\'a eu un problème avec la requete')
    }
  }, false);
}

function change_inputPrice_by_select_typeService(element, forfait, time){
  if(element.value === 'forfait'){
    time.style.display = 'none';
    forfait.style.display = 'block'
  }else{
    forfait.style.display = 'none';
    time.style.display = 'block'
  }
}

// function for get notification message by user role
function ajax_notification_message_by_role(element, selectElement){

    let elementUserSelect = document.getElementById('select_clients');

    element.addEventListener('change', function(){

      if(element.value == 'select'){
        elementUserSelect.style.display = 'block';
      }else{
        elementUserSelect.style.display = 'none';
      }

      let httpRequest = new XMLHttpRequest();
      let currentUrl = window.location.origin + '/';

      let meta = document.getElementById('meta_csrf');
      let metaValue = meta.getAttribute('content');

      var data;

      var params = '?0=' + element.value;

      httpRequest.open('GET', currentUrl + 'get_notification_message_by_role/' + params)
      httpRequest.setRequestHeader('Authorization', 'Bearer ' +  metaValue);
      httpRequest.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      //httpRequest.setRequestHeader("Content-type","application/x-www-form-urlencoded");
      httpRequest.setRequestHeader('X-CSRF-TOKEN', metaValue)
      httpRequest.send();


      httpRequest.addEventListener("loadend", function() {
        //console.log(httpRequest.responseText);
        if (httpRequest.status === 200) {

            while(selectElement.firstChild) {
                selectElement.removeChild(selectElement.firstChild);
              }

              data = JSON.parse(httpRequest.responseText)
              if(data.length > 0) {

                let firstChild = document.createElement('option');
                selectElement.appendChild(firstChild)
                for(let i = 0; i < data.length; i++) {
                  let optionElement = document.createElement('option')
                  optionElement.value = data[i].id
                  optionElement.textContent = data[i].nm_title + ' | ' + data[i].nm_content
                  selectElement.appendChild(optionElement)
                }

              }else{
                let optionElement = document.createElement('option')
                optionElement.value = "";
                optionElement.textContent = 'Aucune message existant pour ce type de client';
                selectElement.appendChild(optionElement)
              }

        }else{
          console.log('il y\'a eu un problème avec la requete')
        }
      }, false);
    })
  }


module.exports = {
  ajax_address_by_user: ajax_address_by_user,
  ajax_technician_category: ajax_technician_category,
  change_inputPrice_by_select_typeService: change_inputPrice_by_select_typeService,
  ajax_notification_message_by_role : ajax_notification_message_by_role
};
