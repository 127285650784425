
//google.maps.event.addDomListener(window, 'load', initialize);

export function initMap() {
    let data = document.querySelector('.data_services');

    const myLatLng = { lat: 48.85717000000005, lng: 2.3414000000000215 };
    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 6,
      center: myLatLng,
    });

    if(data){
        data = JSON.parse(data.textContent);
        console.log(data)
        data.forEach(object =>{
            addMarker(parseFloat(object.ua_latitude), parseFloat(object.ua_longitude), object.service_time, object.s_no, object.id, object.service_status)
        });
      
        function addMarker(lat, lng, service_time, s_no, id, service_status) {
            let marker = new google.maps.Marker({
                position: {lat, lng},
                map,
                title: service_time,
                icon: 'http://maps.google.com/mapfiles/kml/paddle/purple-blank.png'
              });

              const contentString =
              '<div id="content">' +
              '<div id="siteNotice">' +
              "</div>" +
              '<h1 id="firstHeading" class="firstHeading">' + s_no + '</h1>' +
              '<div id="bodyContent">' +
              "<p><b>Heure de l'intervention : </b>" + service_time + "</p>" +
              "<p><b>Statut de l'intervention : </b>" + service_status + "</p>" +
              "<a href=service/" + id +  " class='btn btn-info'>Voir l'intervention</button>"
              "</div>" +
              "</div>";

              const infowindow = new google.maps.InfoWindow({
                content: contentString,
              });
            

              marker.addListener("click", () => {
                infowindow.open({
                  anchor: marker,
                  map,
                  shouldFocus: false,
                  icon: 'http://maps.google.com/mapfiles/kml/paddle/ylw-blank.png'
                });
              });
        }        
    }
}

export function initMapTechnicien() {
    let data = document.querySelector('.data_technciens');

    const myLatLng = { lat: 48.85717000000005, lng: 2.3414000000000215 };
    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 6,
      center: myLatLng,
    });

    if(data){
        data = JSON.parse(data.textContent);
        console.log(data)
        data.forEach(object =>{
            addMarker(parseFloat(object.t_latitude), parseFloat(object.t_longitude), object.t_firstname, object.t_lastname, object.id, object.t_profile_status, object.t_telephone)
        });
      
        function addMarker(lat, lng, t_firstname, t_lastname, id, t_profile_status, t_telephone) {
            let marker = new google.maps.Marker({
                position: {lat, lng},
                map,
                title: t_profile_status,
                icon: 'http://maps.google.com/mapfiles/kml/paddle/ylw-blank.png'
              });

              const contentString =
              '<div id="content">' +
              '<div id="siteNotice">' +
              "</div>" +
              '<h1 id="firstHeading" class="firstHeading">' + t_firstname + ' ' + t_lastname + '</h1>' +
              '<div id="bodyContent">' +
              "<p><b>Statut du technicien : </b>" + t_profile_status + "</p>" +
              "<p><b>Téléphone technicien : </b>" + t_telephone + "</p>" +
              "<a href=technician/" + id +  " class='btn btn-info'>Voir le technicien</button>"
              "</div>" +
              "</div>";

              const infowindow = new google.maps.InfoWindow({
                content: contentString,
              });
            

              marker.addListener("click", () => {
                infowindow.open({
                  anchor: marker,
                  map,
                  shouldFocus: false,
                });
              });
        }        
    }
}

export function initMapUserAddress() {
    let data = document.querySelector('.data_user_address');

    const myLatLng = { lat: 48.85717000000005, lng: 2.3414000000000215 };
    const map = new google.maps.Map(document.getElementById("map"), {
      zoom: 6,
      center: myLatLng,
    });

    if(data){
        data = JSON.parse(data.textContent);
        console.log(data)
        data.forEach(object =>{
            addMarker(parseFloat(object.ua_latitude), parseFloat(object.ua_longitude), object.id, object.ua_name)
        });
      
        function addMarker(lat, lng, id, ua_name) {
            let marker = new google.maps.Marker({
                position: {lat, lng},
                map,
                title: ua_name,
                icon: 'http://maps.google.com/mapfiles/kml/paddle/blu-blank.png'
              });

              const contentString =
              '<div id="content">' +
              '<div id="siteNotice">' +
              "</div>" +
              '<h1 id="firstHeading" class="firstHeading">' + ua_name + '</h1>' +
              '<div id="bodyContent">' +
              "<a href=user_address/" + id +  " class='btn btn-info'>Voir l'adresse cliente</button>"
              "</div>" +
              "</div>";

              const infowindow = new google.maps.InfoWindow({
                content: contentString,
              });
            

              marker.addListener("click", () => {
                infowindow.open({
                  anchor: marker,
                  map,
                  shouldFocus: false,
                });
              });
        }        
    }
}



export function initialize() {

    var input = document.getElementById('autocomplete')
    var autocomplete = new google.maps.places.Autocomplete(input, {
    componentRestrictions: {country: "fr"}
    });

    autocomplete.setFields(
    ['address_components', 'geometry', 'icon', 'name']);

    autocomplete.addListener('place_changed', function () {
        var place = autocomplete.getPlace();
        //var placeDetails = autocomplete.getDetails();

        if(!place.geometry) {
        }else{
        let nbrStreet = document.getElementById('street_nbr');
        let nameStreet = document.getElementById('street_name');
        let city = document.getElementById('city');
        let postalCode = document.getElementById('postalcode');
        let region = document.getElementById('region');
        let country = document.getElementById('country');
        let latitude = document.getElementById('latitude');
        let longitude = document.getElementById('longitude');

        if(longitude != null && latitude != null){
            longitude.value = place.geometry.location.lng()
            latitude.value = place.geometry.location.lat()  
        }

        place.address_components.forEach(function(item){
            if(item.types[0] === 'street_number'){
            
            nbrStreet.value = item.short_name;
            }
            if(item.types[0] === 'route'){
            
            nameStreet.value = item.short_name;
            }
            if(item.types[0] === 'locality' ||item.types[0] === 'political'){
            
            city.value = item.short_name;
            }
            if(item.types[0] === 'postal_code'){
            
            postalCode.value = item.short_name;
            }
            if(item.types[0] === 'administrative_area_level_1'){
            if(region != null){
                region.value = item.long_name;
            }
            }
            if(item.types[0] === 'country'){
            if(country != null){
                country.value = item.long_name;
            }
            }
        })

        }

    });
}