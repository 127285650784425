require('./bootstrap');

import Alpine from 'alpinejs';
import * as myFuncService from './admin/admin'
import * as myFuncGoogle from './google/api'

window.Alpine = Alpine;

Alpine.start();

/* Function admin service */
// get current url + pathname
let currentUrl = window.location.pathname;
console.log(window.location)

if(currentUrl == '/admin/adminSendNotifTechnicien'){
    myFuncService.get_notification_message_by_role()
}

if(location.pathname == '/admin/adminGetServicePerDay'){
  myFuncGoogle.initMap()
}

if(location.pathname == '/admin/adminGetTecnicienMap'){
  myFuncGoogle.initMapTechnicien()
}

if(location.pathname == '/admin/adminGetUserAddressMap'){
  myFuncGoogle.initMapUserAddress()
}




var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
})

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
})
/* globals Chart:false, feather:false */

$(document).ready(function () {
  $("#latitudeArea").addClass("d-none");
  $("#longtitudeArea").addClass("d-none");
});

google.maps.event.addListener(window, 'load', myFuncGoogle.initialize());

myFuncService.get_technician_by_category_window_load();
myFuncService.get_address_by_user();
myFuncService.get_technician_by_category();
myFuncService.change_dispay_price();
